import React from 'react';
import { useSelector } from 'react-redux';
import { withPrefix, graphql } from 'gatsby'

// Helpers
import { formatNumber } from '../../helpers/helpers';

// Layout Imports
import Default from '../../layouts/default';
import Seo from '../../layouts/seo';
import InformationLayout from '../../layouts/information-layout';

// Component imports
import PageHeader from '../../components/page-header';
import Table from '../../components/table';

const AboutChildBenefit = (props) => {
    const pageInfo = props.data.toolInfo;
    const headerImage = pageInfo.edges[0].node.frontmatter.image.relativePath;
    
    // React Redux hooks
    const figures = useSelector(state => state.yearlyFigures);

    const taxYear = `${figures.selectedYear}/${parseInt(figures.selectedYear.slice(-2), 10) + 1}`;

	return (
		<Default>
			<Seo
				title='About Child Benefit'
				description="Let's take a closer look at Child Benefit..."
			/>

			<PageHeader
				heading='About Child Benefit'
				imageSource={`${withPrefix("/")}img/${headerImage}`}
				description="Let's take a closer look at Child Benefit..."
                breadcrumbs={[
                    {name: 'Home', url: '/'},
                    {name: 'Benefits', url: '/benefits'},
                    {name: 'Child Benefit Calculator', url: '/benefits/child-benefit'},
                ]}
			/>

            <InformationLayout
                navLinks={[
                    {name: 'How Child Benefit works', id: 'how-it-works'},
                    {name: 'How much will I be paid?', id: 'how-much'},
                    {name: 'Earnings over £50,000.00', id: 'earnings-over-50k'},
                    {name: 'How to claim Child Benefit', id: 'how-to-claim'},
                    {name: 'Changes in circumstances', id: 'changes-in-circumstances'},
                    {name: 'Challenging a Child Benefit decision', id: 'challenging-a-decision'},
                    {name: 'Other information', id: 'other-information'},
                ]}
            >
                <h2 id='how-it-works'>How Child Benefit works</h2>

                <p>If you are responsible for one or more children you can claim Child Benefit from the government to help you pay for anything you need and boost your household budget.</p>

                <p>The amount you receive in Child Benefit is reduced depending on your income.</p>

                <p>Only one person can receive Child Benefit for a child and is paid every 4 weeks (normally on a Monday or Tuesday) with no limit to how many children you can claim for.</p>

                <h2 id='how-much'>How much Child Benefit will I be paid?</h2>

                <p>You will recieve a weekly payment of {formatNumber(figures.childWeeklyBenefit)} for your eldest or only child and {formatNumber(figures.childAdditionalBenefit)} per additional child.</p>

                <p>Example weekly Child Benefit payments for the tax year {taxYear}:</p>

                <Table style={{maxWidth: '450px'}}>
                    <Table.Header>
                        <th>Number of children</th>
                        <th>Weekly payment</th>
                    </Table.Header>
                    <tbody>
                        <tr>
                            <td>1</td>
                            <td>{formatNumber(figures.childWeeklyBenefit)}</td>
                        </tr>
                        <tr>
                            <td>2</td>
                            <td>{formatNumber(figures.childWeeklyBenefit + figures.childAdditionalBenefit)}</td>
                        </tr>
                        <tr>
                            <td>3</td>
                            <td>{formatNumber(figures.childWeeklyBenefit + (figures.childAdditionalBenefit * 2))}</td>
                        </tr>
                        <tr>
                            <td>4</td>
                            <td>{formatNumber(figures.childWeeklyBenefit + (figures.childAdditionalBenefit * 3))}</td>
                        </tr>
                        <tr>
                            <td>5</td>
                            <td>{formatNumber(figures.childWeeklyBenefit + (figures.childAdditionalBenefit * 4))}</td>
                        </tr>
                    </tbody>
                </Table>

                <h3>State Pension &amp; Child Benefit payments</h3>

                <p>If you are claiming for a child who is under 12 and you’re unemployed or do not earn enough to pay National Insurance contributions, Child Benefit can give you National Insurance credits. National Insurance credits count towards your State Pension so you do not have gaps in your National Insurance record.</p>

                <h3>If a family splits up</h3>

                <ul>
                    <li>If your family splits up and you have one child; you will still recieve a payment of {formatNumber(figures.childWeeklyBenefit)} per week for the eldest child.</li>

                    <li>If you have 2 children and one stays with you and the other stays with your ex-partner; you will both get {formatNumber(figures.childWeeklyBenefit)} per week for each child.</li>

                    <li>If you and your ex-partner make a claim for the same child only one of you will recieve a Child Benefit payment for them.</li>

                    <li>If you have any other children who are elibile for Child Benefit payments then you will recieve {formatNumber(figures.childAdditionalBenefit)} per week for each child.</li>
                </ul>

                <h3>If two families join together</h3>

                <p>If two families join together then the eldest child in the new family will be eligible for the higher payment of {formatNumber(figures.childWeeklyBenefit)} and any other children who are eligible will recieve {formatNumber(figures.childAdditionalBenefit)}.</p>

                <h2 id='earnings-over-50k'>Earnings over {formatNumber(figures.childBenefitThreshold)}</h2>

                <p>If you or your partner earns over {formatNumber(figures.childBenefitThreshold)} per year then the amount of Child Benefit you are entitled to is reduced.</p>

                <p>Your Child Benefit payments are reduced by 1% of your entitlement for every £{figures.childBenefitTaxRate} over the {formatNumber(figures.childBenefitThreshold)} threshold that you earn, up to a maximum income of {formatNumber(figures.childBenefitUpperLimit)} where you will recieve no Child Benefit payments.</p>

                <p>If you earn over {formatNumber(figures.childBenefitThreshold)} then any reduction in your Child Benefit payments will be paid back to the government in tax.</p>

                <h2 id='how-to-claim'>How to claim Child Benefit</h2>

                <p>You can claim Child Benefit as soon as your child is born or an eligible child starts living with you.</p>

                <p>To make a claim, download a form from <a href='https://www.gov.uk/government/publications/child-benefit-claim-form-ch2'>HM Revenue and Customs (HMRC)</a> or phone the Child Benefit Office to send you a claim form: <a href='tel:0300 200 3100'>0300 200 3100</a></p>

                <h2 id='changes-in-circumstances'>Changes in circumstances</h2>

                <p>If your personal circumstances change at any point whilst claiming Child Benefit, you must report these to the Child Benefit Office. These include changes to your:</p>

                <ul>
                    <li>family life, for example getting married</li>
                    <li>child’s life, for example leaving education or training</li>
                    <li>if you want someone else to claim Child Benefit, for example, your partner</li>
                    <li>if you wish to stop your payments at any time</li>
                </ul>

                <h3>If your child starts work or receives benefits in their own right</h3>

                <p>You’ll stop receiving Child Benefit payments immediately if:</p>

                <ul>
                    <li>your child starts paid work for at least 24 hours per week and is no longer in approved education or training</li>
                    <li>your child starts an apprenticeship in England</li>
                    <li>your child starts receiving certain benefits in their own right; e.g. Income Support, Employment and Support Allowance or tax credits</li>
                </ul>

                <h2 id='challenging-a-decision'>Challenging a Child Benefit decision</h2>
                <p>You can challenge a decision about your claim - this is called asking for <a href='https://www.gov.uk/mandatory-reconsideration'>mandatory reconsideration</a>.</p>

                <h2 id='other-information'>Other information</h2>

                <p>If you choose to claim Child Benefit:</p>

                <ul>
                    <li>you can get <a href='https://www.gov.uk/national-insurance-credits/overview'>National Insurance credits</a> which count towards your State Pension</li>
                    <li>your child will automatically get a National Insurance number when they’re 16 years old</li>
                </ul>

                <p>If you choose not to get Child Benefit payments, you should still fill in and send off the claim form.</p>

                <h3>Living abroad</h3>

                <p>If you wish to move outside of the UK you may still be eligible for Child Benefit payments for certain countries or if you’re a Crown servant. <a href='https://www.gov.uk/child-benefit-abroad'>Find out more</a>.</p>

                <h3>If you’ve moved to the UK</h3>

                <p>If you have moved to the UK and you have a ‘Right to Reside’ you will be elibile to receive Child Benefit payments.</p>
            </InformationLayout>
        </Default>
	);
};

export default AboutChildBenefit;

export const AboutChildBenefitQuery = graphql`
    query AboutChildBenefitQuery {
        toolInfo: allMarkdownRemark(
            filter: {
                fileAbsolutePath: {
                    regex: "/src/tool-info/"
                },
                frontmatter: {
                    path: {
                        regex: "/child-benefit/"
                    }
                }
            }
        ) {
            edges {
                node {
                  frontmatter {
                        title
                        image {
                            relativePath
                        }
                        description
                    }
                }
            }
        }
    }
`;